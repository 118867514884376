<template>
  <div class="index">
    <div class="body">
      <div class="search-box">
        <ul class="input-box">
          <li>
            <el-input
              v-model="instrument_id"
              placeholder="请输入机号"
              size="small"
              class="centerInput"
              clearable
            ></el-input>
          </li>
          <li>
            <el-select
              v-model="hospital_id"
              placeholder="请输入医院名称"
              size="small"
              filterable
              clearable
              :remote="province_id==''"
              :remote-method="remoteMethodHospital"
            >
              <el-option
                v-for="item in hospital_options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </li>
          <li>
            <el-select
              v-model="province_id"
              placeholder="请选择省份"
              size="small"
              filterable
              clearable
              @change='changeProvince'
            >
              <el-option
                v-for="item in province_options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </li>
          <li>
            <el-input
              v-model="batch_id"
              placeholder="请输入试剂批号"
              size="small"
              class="centerInput"
              clearable
            ></el-input>
          </li>
          <li>
            <el-date-picker
              class="calendarDate"
              v-model="time_data"
              size="small"
              range-separator="-"
              type="daterange"
              value-format="yyyy-MM-dd"
              format="yyyy/MM/dd"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              :picker-options="pickerOptions"
              >
            </el-date-picker>
          </li>
        </ul>
        <div class="btn-box">
          <el-button type="primary" class="btn" :disabled="loading" size="small" @click="page=1;getListFun()">查 询</el-button>
        </div>
      </div>
      <div class="table-box">
        <el-table
          :data="tableData"
          v-loading="loading"
          style="width: 100%">
          <el-table-column type="index" width="100"  label="序号" align="center">
            <template scope="scope">
              <span>{{serialtext(scope.$index)}}</span>
            </template>
          </el-table-column>
          <el-table-column
            label="医院"
            align="center"
            width="200"
            prop="hospital_name_extra">
          </el-table-column>
          <el-table-column
            label="位置"
            align="center"
            prop="hos_position_name_extra">
          </el-table-column>
          <el-table-column
            label="平台"
            align="center"
            prop="platform_unique_extra">
          </el-table-column>
          <el-table-column
            label="机号"
            align="center"
            prop="instrument_id_extra">
          </el-table-column>
          <el-table-column
            label="样本编号"
            align="center"
            prop="number_samples">
          </el-table-column>
          <el-table-column
            label="检测项目"
            align="center"
            prop="testing_unique_extra">
          </el-table-column>
          <el-table-column
            label="试剂批号"
            align="center"
            prop="batch">
          </el-table-column>
          <el-table-column
            label="检测时间"
            align="center"
            prop="corrected_date">
            <template slot-scope="scope">
              <span v-if="scope.row.corrected_date" v-html="scope.row.corrected_date.replace(/\s+/gm, '<br>')"></span>
            </template>
          </el-table-column>
          <el-table-column
            :render-header="renderHeader"
            label="SCr结果|μmol/L"
            align="center"
            prop="sample_check_result">
            <template  slot-scope="scope">
              <span>{{scope.row.sample_check_result?scope.row.sample_check_result.split('μ')[0]:''}} </span>

            </template>
          </el-table-column>
          <el-table-column
            :render-header="renderHeaderSup"
            label="eGFR结果|mL/(min·1.73m"
            align="center"
            :width="flexColumnWidth(170,110)"
            prop="egfr_result">
            <template slot-scope="scope">
              <span>{{scope.row.egfr_result}} </span>
            </template> 
          </el-table-column>
          <el-table-column type="expand"  label="操作" width="90" v-if="egfr_operation_btn">
            <template slot-scope="scope">
              <div class="expand-box">
                <el-form  label-width="80px" size="mini">
                  <el-form-item label="公式">
                    <el-select size="mini" v-model="scope.row.formula_id" placeholder="请选择计算公式">
                      <el-option
                        v-for="item in formula_options"
                        class="my-select-dropdown__item"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                      </el-option>
                    </el-select>
                    <a @click="dialogVisible = true">
                      查看公式
                    </a>
                  </el-form-item>
                  <el-form-item label="性别">
                    <el-select v-model="scope.row.gender" size="mini" placeholder="请选择性别">
                      <el-option
                        v-for="item in gender_options"
                        class="my-select-dropdown__item"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                      </el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item label="年龄">
                    <div class="form-item-box">
                      <p>
                        <el-input v-model="scope.row.age" size="mini"></el-input>
                      </p>
                      <span>岁</span>
                    </div>
                  </el-form-item>  
                  <el-form-item label="身高">
                    <div class="form-item-box">
                      <p>
                        <el-input v-model="scope.row.height" size="mini"></el-input>
                      </p>
                      <span>cm</span>
                    </div>
                  </el-form-item>
                </el-form>
                <div class="btn1-box">
                  <el-button type="primary" size="mini" :disabled="loading" @click="confirmCountSave(scope.row)" >计算保存</el-button>
                </div>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          :hide-on-single-page="total <= 0"
          background
          layout="total,prev, pager, next,jumper"
          :total="total"
          :current-page.sync="page"
          :disabled="loading"
          @current-change="handleSizeChange"
        />
      </div>

    </div>
    <el-dialog
      title="eGFR计算公式"
      :visible.sync="dialogVisible"
      width="40%"
      >
      <div class="dialog-body">
        <div class="dialog-body-item">
          <p>公式1(简化MDRD-中国人公式)：</p>
          <p>eGFR[mL/ (min · 1.73 m<sup>2</sup>)] = 186 x[SCr(μmol/L) x 0.011312]<sup>-1.154</sup>x年龄<sup>-0.179</sup>(女性x0.762)(x1.233 中国人)</p>
        </div>
        <div class="dialog-body-item">
          <p>公式2(CKD-EPI公式)：</p>
          <p >(1)男性：</p>
          <p style="text-indent: 4em;">SCr<0.9 mg/dL:</p>
          <p style="text-indent: 6em;">eGFR[mL/(min · 1.73m<sup>2</sup>)]=141x[SCr(mg/dL)/0.9]<sup>-0.411</sup>x0.993<sup>Age</sup></p>
          <p style="text-indent: 4em;">SCr>0.9 mg/dL:</p>
          <p style="text-indent: 6em;">eGFR[mL/(min · 1.73m<sup>2</sup>)]=141x[SCr(mg/dL)/0.9]<sup>-1.209</sup>x0.993<sup>Age</sup></p>
          <p>(2)女性：</p>
          <p style="text-indent: 4em;">SCr<0.7mg/dL:</p>
          <p style="text-indent: 6em;">eGFR[mL/(min · 1.73m<sup>2</sup>)] =144x[SCr(mg/dL)/0.7]<sup>-0.329</sup>x0.993<sup>Age</sup></p>
          <p style="text-indent: 4em;">SCr>0.7mg/dL:</p>
          <p style="text-indent: 6em;">eGFR[mL/(min · 1.73m<sup>2</sup>)] =144x[SCr(mg/dL)/0.7]<sup>-1.209</sup>x0.993<sup>Age</sup></p>
        </div>
      </div>
     
    </el-dialog>
  </div>
</template>

<script>
import { hospitals_list } from '@/api/search.js'
import { Message } from 'element-ui'
import api from '@/api/tongji';
import apiOther from "@/api/other"
import { getEgfrOperationBtn } from '@/router/menuMain.js'
import {flexColumnWidth} from '@/assets/js/adaption.js'

export default {
  data() {
    return {
      hospital_options: [], //医院列表
      province_options: JSON.parse(localStorage.getItem('province')), //地区下拉框
      province_hospital_list: [],
      egfr_operation_btn: true,
      instrument_id: '',
      hospital_id: '',
      province_id: '',
      batch_id: '',
      time_data: null,
      page: 1,
      pageNum: 1,
      pickerOptions: {
        disabledDate(time) {
            return time.getTime() > Date.now();
        }
      },
      tableData: [],
      formula_options: [{
          value: 1,
          label: '公式1(简化MDRD-中国人公式)'
        },{
          value: 2,
          label: '公式2(CKD-EPI公式)'
        },],
        gender_options: [{
          value: 1,
          label: '男'
        },{
          value: 2,
          label: '女'
        },],
      dialogVisible: false,
      total: 10,
      loading: false,
    }
  },
  created() {
    // egfr暂时不使用权限控制
    // this.egfr_operation_btn = getEgfrOperationBtn();
  },
  mounted() {
    this.getProvinceHospital();
    this.getListFun();
  },
  computed: {
    serialtext() {
      return (index)=> {
        return (this.pageNum-1)*10+index+1
      }
    }
  },
  methods: {
     // 表格列宽
     flexColumnWidth(width,minWidth) {
      return flexColumnWidth(width,minWidth)
    },
    // 表格头换行
    renderHeader(h, { column }) {
      return h('span', {}, [
        h('span', {}, column.label.split('|')[0]),
        h('br'),
        h('span', {}, column.label.split('|')[1]),
      ])
    },
    // 表格头换行加上标
    renderHeaderSup(h, { column }) {
      return h('span', {}, [
        h('span', {}, column.label.split('|')[0]),
        h('br'),
        h('span', {}, column.label.split('|')[1]),
        h('sup', {}, 2),
        h('span', {}, ')'),

      ])
    },
    // 远程搜索医院
    remoteMethodHospital(e) {
      this.hospital_options = hospitals_list({ hospital_name: e })
    },
    // 根据省份获取医院列表
    getProvinceHospital() {    
      api.province_hospital({}).then((res) => {
        if (res.code === 200) {
          this.province_hospital_list = res.data.data;
        }
      });
    },
    // 省份改变
    changeProvince() {
      this.hospital_options = this.province_hospital_list.find(e=> this.province_id==e.province_id).hospital_list.map(e=> {
        return {
          value: e.hospital_id,
          label: e.hospital_full_name,
        }
      })
      this.hospital_id='';
    },
    // 页面切换获取数据
    handleSizeChange(val) {
      this.page = val
      this.getListFun()
    },
    // 获取数据
    getListFun() {
      let param = {
        instrument_id: this.instrument_id,
        hospital_id: this.hospital_id,
        province_id: this.province_id,
        batch_id: this.batch_id,
        page: this.page,
        size: 10,
      }
      if(this.time_data!=null){
        param.start_time = this.time_data[0]
        param.end_time = this.time_data[1]
      }
      this.loading = true;
      apiOther.egfr_list(param).then(res => {
        this.pageNum = this.page
        this.total = res.data.count
        this.tableData = res.data.data
      }).finally(msg => {
        this.loading = false;
      });
    },
    // 计算保存
    confirmCountSave(row) {
      let {age,gender,formula_id,height,sample_check_id,sample_check_result } = {...row}
      if(age === '') {
        Message.warning("请输入年龄")
        return
      };
      if(formula_id == '') {
        Message.warning("请选择公式")
        return
      };
      if(height === '') {
        Message.warning("请输入身高")
        return
      };
      if(gender === '') {
        Message.warning("请选择性别")
        return
      };
      let sample_result = sample_check_result.split('μmol/L')[0]
      this.loading = true;
      apiOther.save_egfr({age,gender,formula_id,height,sample_check_id,sample_result}).then(res => {
        row.egfr_result = res.data.egfr_result.toFixed(2);
      }).finally(msg => {
        this.loading = false;
      });
    },
  }
}
</script>

<style lang="scss" scoped>
  $gap: px(10);

  .index {
    padding: $gap;
    .title {
      font-size: 20px;
      font-weight: bold;
      color: #111111;
      margin-bottom: 20px;
    }
  }
  .body {
    background-color: #fff;
    padding: px(10);
    .search-box {
      .input-box {
        display: flex;
        gap: px(20);
      }
      
      .btn-box {
        margin-top: px(10);
        padding-bottom: px(10);
        border-bottom: 1px solid #eee;
        ::v-deep .el-button--small {
          font-size: px(16);
        }
      }
      .calendarDate {
        width: px(400) !important;
      }
      ::v-deep .el-range-separator {
        //color: #ccc;
      }
      ::v-deep .el-range-editor--small .el-range-input {
        font-size: px(16) !important;
      }
    }

    .table-box {
      ::v-deep .el-table__expand-icon {
        .el-icon-arrow-right:before {
          font-weight: bold;
          //color: #409EFF;
          color: #F59A23;
          content: "\e6d9";
        }
      }
      ::v-deep .el-table__expand-icon--expanded {
        transform: rotate(0deg);
        .el-icon-arrow-right:before {
          font-weight: bold;
         // color: #409EFF;
          content: "\e6d8";
        }
      }
      ::v-deep .el-table__expand-icon>.el-icon {
        margin: 0;
        transform: translate(-50%,-50%);
      }
      .expand-box {
        display: flex;
        align-items: end;
       
        ::v-deep .el-form-item__label {
          font-size: px(16);
        }
        ::v-deep .el-form-item {
          margin: px(0);
        }
        a {
          text-decoration: underline;
          color: #409EFF;
          cursor: pointer;
          text-underline-offset: px(5); 
          vertical-align: sub;
          margin-left: px(5);
        }
        a:hover {
          opacity: 0.7;
        }
        .form-item-box {
          p { 
            display: inline-block;
          }
          span {
            margin-left: px(5);
          }
        }
        ::v-deep .el-button--mini {
          font-size: px(15);
        }
      }
    }
  }
  ::v-deep .el-dialog__body {
    padding-top: 0;
  }
  .dialog-body {
    
    .dialog-body-item:first-child {
      border-bottom: 1px solid #eee;
      margin-bottom: $gap;
      padding-bottom: $gap;
    }
    .dialog-body-item {
      p:first-child {
        color: #F59B26;
        text-indent: 0em;
        font-size: px(16);

      }
      p {
        color: #000;
        text-indent: 2em;
        font-size: px(18);
        line-height: px(30);
      }
    }
  }
  .my-select-dropdown__item {
    font-size: px(16);
  }

</style>