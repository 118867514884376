import { render, staticRenderFns } from "./eGFR.vue?vue&type=template&id=75402dea&scoped=true&"
import script from "./eGFR.vue?vue&type=script&lang=js&"
export * from "./eGFR.vue?vue&type=script&lang=js&"
import style0 from "./eGFR.vue?vue&type=style&index=0&id=75402dea&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "75402dea",
  null
  
)

export default component.exports